import styled from 'styled-components';

const Root = styled.div`
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.2));
  border-radius: 57px;
  background-color: var(--black-white-transparent);
  border: var(--border-color-grey) 0.5px solid;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  bottom: 10px;
  backdrop-filter: blur(3px);
  padding: 10px;
  z-index: 1000;
`;

export default Root;
