import { css } from 'styled-components';

export default css`
  --brand-green: #2bd283;
  --brand-green-dark: #219f62;
  --brand-green-2: #7bcf89;
  --brand-blue: #0078ff;
  --brand-light-blue: var(--brand-blue);

  --red: #ff4f4f;
  --dark-red: #66292a;
  --orange-red: #ff2828;
  --orange-red-2: #ff002a;
  --light-red: #66292a;
  --black: #ffffff;

  --light-gray: #494a4a;
  --light-grey: var(--light-gray);
  --dark-gray: #363636;
  --dark-grey: var(--dark-gray);
  --gray: #606060;
  --grey: var(--gray);
  --icon-gray: #686868;

  --input-bg-mod: #363636;

  --blue: var(--brand-blue);
  --blue-secondary: #3494ff;
  --blue-darker: #0459b9;

  --green: var(--brand-green);
  --green-secondary: #44c88a;
  --green-plus: #26d730;

  --yellow: #ffb400;
  --yellow-secondary: #fdbe00;
  --yellow-bright: #ffd800;
  --purple: #9524ff;

  --white: #242526;
  --white-box-background: #242526;
  --dark-box-background: #18191a;
  --dark-box-border: #202020;
  --main-menu-background: #242526;
  --box-background: #222a25;

  --error-color: #ff4f4f;

  --text-color-blue: #0078ff;
  --text-color-light-blue: #ffffff;

  --text-color-white: #000000;
  --text-grey: #adadad;
  --text-black-grey: #adadad;
  --text-color-white-gray: #999999;
  --text-color-light-gray: #8c8c8c;
  --text-color-medium-gray: #898989;
  --text-color-dark-gray: #ffffff;
  --text-color-black: #ffffff;
  --text-color: #ffffff;
  --text-color-text-editor-active: #c3c4c4;
  --text-color-text-editor-notactive: #656566;

  --bg-color-text-input: #3a3b3c;
  --bg-color-grey-button: #3a3b3c;
  --bg-color-white-gray: #18191a;
  --bg-color-white-dark-gray: rgba(96, 96, 96, 0.25);
  --bg-color-light-gray: #242526;
  --bg-color-light-secondary-gray: #3a3b3c;
  --bg-color-main-block: #18191a;
  --bg-color-disabled: var(--dark-gray);
  --bg-color-capsule: #242526;
  --bg-hover-capsule: #313233;
  --bg-switch: #494a4a;
  --bg-input-color-editor: #232425;
  --bg-color-media-asset: #3a3b3c;

  --border-color: #404040;
  --border-color-grey: rgba(173, 173, 173, 0.1);
  --bg-text-editor-color: #242526;
  --bg-media-button: #898989;
  --bg-indicator: var(--gray);

  --container-width: 1240px;
  --white-white: #ffffff;
  --white-black: #ffffff;
  --black-white: #000000;
  --black-white-transparent: rgba(0, 0, 0, 0.8);

  --color-danger: #ff5959;

  /* Fonts */
  --google-font: 'Google Sans', sans-serif;
  --noto-font: 'Noto Sans', sans-serif;
  --lato-font: 'Lato', sans-serif;
  --gilroy-font: 'Gilroy Extra', sans-serif;
  --google-sora: 'Google Sora', sans-serif;
  --google-sora-cdn: 'Sora', sans-serif;
  --base-font: var(--google-sora-cdn);

  --menu-height: 110px;

  /* Transitions */
  --ease-out-cubic: cubic-bezier(0.22, 0.61, 0.36, 1);

  /* Shared attributes */
  --block-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.05);
  --block-shadow-alt: 0 8px 9px 0 rgba(0, 0, 0, 0.01);
  --block-border-radius: 10px;
  --block-spacing: 10px;

  /* TODO: NEW VARIABLES */
  --primary-1: hsl(152, 66%, 50%);
  --primary-1-light: hsl(212, 100%, 84%);
  --primary-2: hsl(212, 100%, 50%);

  --rgb-gradient: linear-gradient(to right, #037cfa, #c325d1, #ffb930, #ff6767);
  --hover-transition: 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
`;
