import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BackIcon } from 'assets/icons/arrow-outlined-left.svg';
import { ReactComponent as GoalsFilledIcon } from 'assets/icons/mainmenu/goals-filled.svg';
import { ReactComponent as GoalsIcon } from 'assets/icons/mainmenu/goals-outlined.svg';
import { ReactComponent as HomeFilledIcon } from 'assets/icons/mainmenu/home-filled.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/mainmenu/home-outlined.svg';
import { ReactComponent as LaptopFilledIcon } from 'assets/icons/mainmenu/laptop-filled.svg';
import { ReactComponent as LaptopIcon } from 'assets/icons/mainmenu/laptop-outlined.svg';
import { ReactComponent as ListFilledIcon } from 'assets/icons/mainmenu/list-filled.svg';
import { ReactComponent as ListIcon } from 'assets/icons/mainmenu/list-outlined.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/mainmenu/support-outlined.svg';
import { ReactComponent as WalletFilledIcon } from 'assets/icons/mainmenu/wallet-filled.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/mainmenu/wallet-outlined.svg';
import { ReactComponent as WindowFilledIcon } from 'assets/icons/mainmenu/window-filled.svg';
import { ReactComponent as WindowIcon } from 'assets/icons/mainmenu/window-outlined.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/power-outlined.svg';

import Text from 'components/Text';
import { toggleMobileNav } from 'store/actions/app';
import getUserAvatar from 'utils/getUserAvatar';
import logout from 'utils/logout';

import Logo from '../Logo';
import Avatar from '../Tools/styles/Avatar';
import NavItem from './components/NavItem';
import Bar from './styles/Bar';
import BurgerButton from './styles/BurgerButton';
import Columns from './styles/Columns';
import FooterButton from './styles/FooterButton';
import MobileNavWrapper from './styles/MobileNavWrapper';
import NavItems from './styles/NavItems';
import Overlay from './styles/Overlay';
import OverlayContent from './styles/OverlayContent';
import Notice from './styles/Notice';

const MobileNav = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData.profile);
  const isOpen = useSelector(state => state.app.isMobileNavOpen);

  const userAvatar = getUserAvatar(userData);

  const toggleMenu = () => {
    dispatch(toggleMobileNav());
  };

  return (
    <MobileNavWrapper>
      <BurgerButton onClick={toggleMenu}>
        <Bar />
        <Bar />
        <Bar />
      </BurgerButton>
      {isOpen && (
        <Overlay>
          <Columns>
            <Logo />
            <Avatar
              style={{
                backgroundImage: userAvatar ? `url(${userAvatar})` : userAvatar,
              }}
            />
          </Columns>
          <OverlayContent>
            <Text noMargin weight="bold" size={35}>
              Menu
            </Text>
            <NavItems>
              <NavItem
                to="/panel-uzytkownika"
                icon={<HomeIcon width={30} height={27} />}
                activeIcon={<HomeFilledIcon width={30} height={27} />}
                label="Panel użytkownika"
              />
              <NavItem
                to="/lista-wiadomosci"
                icon={<ListIcon width={30} height={28} />}
                activeIcon={<ListFilledIcon width={30} height={28} />}
                label="Lista wiadomości"
              />
              <NavItem
                to="/wyplaty"
                icon={<WalletIcon width={30} height={28} />}
                activeIcon={<WalletFilledIcon width={30} height={28} />}
                label="Wypłaty"
              />
              <NavItem
                to="/konfigurator"
                icon={<WindowIcon width={30} height={30} />}
                activeIcon={<WindowFilledIcon width={30} height={30} />}
                label="Konfigurator"
              />
              <NavItem
                to="/cele"
                icon={<GoalsIcon width={30} height={28} />}
                activeIcon={<GoalsFilledIcon width={30} height={28} />}
                label="Cele wpłat"
              />
              <NavItem
                to="/ustawienia-strony-zamowien"
                icon={<LaptopIcon width={31} height={27} />}
                activeIcon={<LaptopFilledIcon width={31} height={27} />}
                label="Ustawienia strony"
              />
              <NavItem
                icon={<SupportIcon width={30} height={30} />}
                label="Uzyskaj pomoc"
                color="var(--text-color-blue)"
                onClick={() => window.LiveChatWidget.call('maximize')}
              />
            </NavItems>
            <Notice>
              Dostęp do pozostałych podstron m.in. zakładki z danymi osobowymi, dostępny wyłącznie w
              widoku dla komputerów stacjonarnych.
            </Notice>
          </OverlayContent>
          <Columns>
            <FooterButton onClick={toggleMenu}>
              <BackIcon width={30} height={26} />
              Wróć
            </FooterButton>
            <FooterButton color="red" onClick={logout}>
              <LogoutIcon width={26} height={26} />
              Wyloguj
            </FooterButton>
          </Columns>
        </Overlay>
      )}
    </MobileNavWrapper>
  );
};

export default MobileNav;
