import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import Text from '../../Text';

const ProfilePendingConfirmedAsUserNotification = ({ notification }) => (
  <>
    <Text size={21} margin="0 0 40px">
      Wniosek został pomyślnie przesłany do weryfikacji przez administratora.
    </Text>
    <Text size={12} margin="0 0 40px">
      W ciągu 24 godzin zweryfikujemy przesłane zmiany w danych osobowych. Do momentu weryfikacji
      nie możesz zlecić wypłaty, ani zmienić danych ponownie.
    </Text>
    <Text size={12} margin="0 0 40px">
      Poczekaj cierpliwie na zakończenie weryfikacji – zajmiemy się wnioskiem najszybciej jak to
      tylko możliwe. Nie można przyśpieszyć tego procesu. Po weryfikacji otrzymasz komunikat
      mailowy.
    </Text>
    <Text marign="0 0 50px">
      Zaakceptowano:
      <br />
      {fecha.format(new Date(notification.payload.at), 'DD/MM/YY HH:mm')}
    </Text>
  </>
);

export default ProfilePendingConfirmedAsUserNotification;

ProfilePendingConfirmedAsUserNotification.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
};
