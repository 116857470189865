import React from 'react';
import styled from 'styled-components';

import { ReactComponent as OpenRawIcon } from 'assets/icons/external-link.svg';
import { ClearButton } from 'components/styled';
import openDialogWindow from 'utils/openDialogWindow';

const OpenButton = styled(ClearButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 174px;
  height: 24px;
  padding: 0;
  color: var(--text-black-grey);
  .label {
    font-size: 9px;
    font-weight: bold;
  }
  svg {
    fill: currentColor;
    width: 10px;
    height: 10px;
  }
`;

const OpenRawViewButton = () => (
  <OpenButton onClick={() => openDialogWindow('/lista-wiadomosci?raw')}>
    <OpenRawIcon />
    <div className="label">OTWÓRZ W NOWYM OKNIE</div>
  </OpenButton>
);

export default OpenRawViewButton;
