import styled, { css } from 'styled-components';

import Button from 'components/Button';

const defaultBgColor = 'var(--bg-color-white-dark-gray)';
const defaultBorderColor = 'var(--border-color-grey)';

const rounded = css`
  padding-left: 12px;
  padding-right: 12px;
`;

const ControlButton = styled(Button)`
  background-color: ${defaultBgColor};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  border: var(--border-color-grey) 0.5px solid;
  color: var(--white-black);

  .label {
    height: 15px;
  }

  & > div {
    justify-content: center;
    gap: 7px;
  }

  svg {
    height: 11px;
  }

  ${props => props.rounded && rounded}
`;

export const YellowControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(255, 185, 48, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#FFB930' : defaultBorderColor)};
  width: 150px;
  height: 36px;
  padding: initial;
`;

export const YellowControlButtonMobile = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(255, 185, 48, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#FFB930' : defaultBorderColor)};
  width: 36px;
  height: 36px;
  padding: initial;
`;

export const RedControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(255, 89, 89, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#FF6767' : defaultBorderColor)};
  width: 36px;
  height: 36px;
  padding: initial;
`;

// 037CFA to rgba(3, 124, 250, 0.25)
export const BlueControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(3, 124, 250, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#037CFA' : defaultBorderColor)};
  width: 150px;
  height: 36px;
  padding: initial;
`;
