import styled from 'styled-components';

const Footer = styled.div`
  width: 174px;
  border-radius: 57px;
  background-color: var(--black-white-transparent);
  border: var(--border-color-grey) 0.5px solid;
  display: flex;
  align-items: center;
  gap: 10px;
  backdrop-filter: blur(3px);
  z-index: 1000;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`;

export default Footer;
