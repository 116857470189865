import styled from 'styled-components';

const Root = styled.div`
  transform: scale(1.25) translateX(-40%);
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform-origin: center;
  &.raw {
    transform: scale(1.25) translateX(-40%) !important;
    left: 50% !important;
    bottom: 10px !important;
  }
  &.mobile {
    transform: scale(1.25);
    left: 60px;
    bottom: 3px;
  }
  z-index: 10000;
`;

export default Root;
