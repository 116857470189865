import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from 'components/Text';
import Dialog from 'components/Dialog';
import store from 'store';
import { toggleVerifyWithBankWizard } from 'store/actions/userData';
import serverAlert from 'store/utils/serverAlert';

import { ReactComponent as CompleteIcon } from 'assets/icons/complete-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';

import { isArray } from 'utils/validators';
import http from 'utils/http';

import { showPointsAndBonuses } from 'components/MenuTop/components/MainMenu';

import Tip100Achievement from './components/achievements/Tip100Achievement';
import Tip20Achievement from './components/achievements/Tip20Achievement';
import WithdrawalsActivatedAchievement from './components/achievements/WithdrawalsActivatedAchievement';
import BankTransferValidatedNotification from './components/BankTransferValidatedNotification';
import BankTransferValidationRejectedNotification from './components/BankTransferValidationRejectedNotification';
import ProfileVerifiedNotification from './components/ProfileVerifiedNotification';
import CommissionThresholdChangeNotification from './components/CommissionThresholdChangeNotification';
import WithdrawalAcceptedNotification from './components/WithdrawalAcceptedNotification';
import WithdrawalRejectedNotification from './components/WithdrawalRejectedNotification';
import ExternalTipsSourceDisconnectedNotification from './components/ExternalTipsSourceDisconnectedNotification';
import ProfileRejectedNotification from './components/ProfileRejectedNotification';

import Button from './styles/Button';
import CancelButton from './styles/CancelButton';
import Icon from './styles/Icon';
import Baloons from './styles/Baloons';
import MessageContainer from './styles/MessageContainer';

import baloonsImage from './assets/baloons.png';
import AdminNotification from './components/AdminNotification';
import ProfilePendingConfirmedAsUserNotification from './components/ProfilePendingConfirmedAsUserNotification';

class Notifications extends Component {
  static propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    showVerifyWithBankTransferModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      isVisible: false,
    };
  }

  async componentDidMount() {
    let notifications = [];

    try {
      const { data } = await http.get('/user/notifications');
      notifications = this.filterNotifications(data);
    } catch (error) {
      serverAlert('Wystąpił błąd podczas pobierania powiadomień.');
    }

    if (!isArray(notifications)) {
      notifications = [];
    }

    setInterval(() => {
      http.get('/user/notifications').then(({ data }) => {
        this.setState({
          notifications: this.filterNotifications(data),
        });
      });
    }, 240000);

    this.setState({
      isVisible: notifications.length > 0,
      notifications,
    });
  }

  filterNotifications = items => items.filter(notify => !notify.read_at);

  /**
   * Funkcja renderująca zawartość popupu
   *
   * @returns JSX.Element|null
   */
  renderLayout = () => {
    const notification = this.getNotification();
    const { userData } = store.getState();

    switch (notification.type) {
      case 'profile_verified':
        return <ProfileVerifiedNotification notification={notification} />;
      case 'admin_notification':
        return <AdminNotification notification={notification} />;
      case 'profile_pending_confirmed_by_user':
        return <ProfilePendingConfirmedAsUserNotification notification={notification} />;

      case 'profile_rejected':
        return (
          <ProfileRejectedNotification
            notification={notification}
            isCompany={userData.profile.is_company}
          />
        );
      case 'commission_threshold_change':
        return <CommissionThresholdChangeNotification notification={notification} />;
      case 'withdrawal_accepted':
        return (
          <WithdrawalAcceptedNotification
            notification={notification}
            methodName={this.getWithdrawalMethodName(notification.payload.method_name)}
          />
        );
      case 'withdrawal_rejected':
        return (
          <WithdrawalRejectedNotification
            notification={notification}
            methodName={this.getWithdrawalMethodName(notification.payload.method_name)}
          />
        );
      case 'validated_with_bank_transfer':
        return <BankTransferValidatedNotification />;
      case 'validation_with_bank_transfer_rejected':
        return <BankTransferValidationRejectedNotification notification={notification} />;
      case 'achievement_20_tips':
        return <Tip20Achievement />;
      case 'achievement_100_tips':
        return <Tip100Achievement />;
      case 'achievement_withdrawals_active':
        return <WithdrawalsActivatedAchievement />;
      default:
        return null;
    }
  };

  getWithdrawalMethodName = methodName => {
    let name = '';

    switch (methodName) {
      case 'paypal':
        name = 'Ekspresowy przelew na konto PayPal';
        break;
      case 'bank_standard':
        name = 'Ekspresowy przelew na konto bankowe';
        break;
      case 'bank_express':
        name = 'Natychmiastowy przelew na konto bankowe';
        break;
      default:
        name = methodName;
    }

    return name;
  };

  /**
   * Zwraca najnowszy obiekt powiadomienia
   *
   * @returns {object}
   */
  getNotification = () => {
    const { notifications } = this.state;
    const notification = notifications[0];

    return notification || {};
  };

  /**
   * Funkcja wykonująca się po naciśnięciu przycisku z wykonaniem akcji
   *
   * @returns {void}
   */
  handleClickActionButton = () => {
    const notification = this.getNotification();
    const { history, showVerifyWithBankTransferModal } = this.props;

    switch (notification.type) {
      case 'profile_verified':
      case 'achievement_withdrawals_active':
      case 'achievement_100_tips':
        history.push('/wyplaty');
        break;
      case 'commission_threshold_change':
        showPointsAndBonuses();
        break;
      case 'external_disconnected':
        history.push('/ustawienia-strony-zamowien/podlacz-zewnetrzne-zrodlo');
        break;
      case 'achievement_20_tips':
        showVerifyWithBankTransferModal();
        break;
      default:
        break;
    }

    this.markAsRead();
  };

  /**
   * Funkcja wykonująca się po naciśnięciu przycisku "ZAMKNIJ"
   *
   * @returns {void}
   */
  handleClickCloseButton = () => {
    this.markAsRead();
  };

  /**
   * Funkcja oznaczająca powiadomienie jako przeczytane oraz zamykająca popup, jeżeli nie ma więcej wiadomości
   * do wyświetlenia
   *
   * @returns {void}
   */
  markAsRead() {
    const { notifications } = this.state;
    const copy = [...notifications];
    const shifted = copy.shift();

    http.patch(`/notifications/${shifted.id}/read`).catch(error => {
      serverAlert('Wystąpił błąd podczas aktualizacji odczytania powiadomienia.');
    });

    this.setState(
      {
        notifications: copy,
      },
      () => {
        if (copy.length <= 0) {
          this.setState({ isVisible: false });
        }
      },
    );
  }

  render() {
    const { isVisible } = this.state;
    const notification = this.getNotification();

    if (notification.type === 'external_disconnected') {
      return (
        <Dialog width={720} noPadding noBorder isVisible={isVisible}>
          <ExternalTipsSourceDisconnectedNotification
            onActionButtonClick={this.handleClickActionButton}
          />
        </Dialog>
      );
    }

    let hideCancelButton = false;
    let showBalloons = false;
    let buttonText = '';
    let titleText = notification.type;

    let isSuccess = true;

    switch (notification.type) {
      case 'profile_verified':
        buttonText = 'Wypłać teraz';
        titleText = 'Gratulacje!';
        showBalloons = true;
        break;
      case 'profile_pending_confirmed_by_user':
        buttonText = 'Ok';
        titleText = 'Gratulacje!';
        showBalloons = true;
        break;

      case 'commission_threshold_change':
        buttonText = 'Sprawdź szczegóły';
        titleText = 'Gratulacje!';
        showBalloons = true;
        break;
      case 'withdrawal_accepted':
        titleText = 'Wypłata została zrealizowana!';
        buttonText = 'Dzięki wielkie';
        hideCancelButton = true;
        showBalloons = true;
        break;
      case 'withdrawal_rejected':
        titleText = 'Wypłata odrzucona!';
        buttonText = 'OK';
        isSuccess = false;
        break;
      case 'profile_rejected':
        titleText = 'Odrzucono dane osobowe!';
        buttonText = 'OK';
        isSuccess = false;
        break;
      case 'admin_notification':
        titleText = 'Powiadomienie od administratora';
        buttonText = 'OK';
        break;
      case 'validated_with_bank_transfer':
        titleText = 'Otrzymałeś znaczek weryfikacji!';
        buttonText = 'OK';
        showBalloons = true;
        break;
      case 'validation_with_bank_transfer_rejected':
        titleText = 'Niestety, nie tym razem…';
        buttonText = 'Rozumiem';
        isSuccess = false;
        break;
      case 'achievement_20_tips':
      case 'achievement_withdrawals_active':
        titleText = 'Gratulacje!';
        buttonText = 'Sprawdź!';
        showBalloons = true;
        break;
      case 'achievement_100_tips':
        titleText = 'Odblokowano nową funkcję!';
        buttonText = 'Sprawdź!';
        showBalloons = true;
        break;
      default:
        break;
    }

    return (
      <Dialog isVisible={isVisible}>
        {showBalloons && <Baloons src={baloonsImage} />}

        <MessageContainer>
          {isSuccess ? (
            <Icon style={{ backgroundColor: `var(--green)` }}>
              <CompleteIcon width="40px" height="40px" />
            </Icon>
          ) : (
            <Icon style={{ backgroundColor: `var(--red)` }}>
              <ErrorIcon width="40px" height="40px" />
            </Icon>
          )}

          <Text family="lato" size={24} weight="bold" margin="0 0 30px">
            {titleText}
          </Text>

          {this.renderLayout()}

          {buttonText && (
            <Button
              type="button"
              background={isSuccess ? 'green' : 'red'}
              block
              onClick={this.handleClickActionButton}
            >
              {buttonText}
            </Button>
          )}
        </MessageContainer>

        {!hideCancelButton && (
          <CancelButton type="button" onClick={this.handleClickCloseButton}>
            ZAMKNIJ
          </CancelButton>
        )}
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showVerifyWithBankTransferModal: () => dispatch(toggleVerifyWithBankWizard(true)),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(Notifications),
);
